<template>
    <div class="alert-call-request__associate-name">
        <img :src="`${filesPath}${associate.info.associateImage}`" width="35" height="35" alt="" v-if="associate.info.associateImage">
        <img src="@/assets/images/user-profile.svg" width="35" height="35" v-else>

        {{ associate.info.associateName }} request 911 call.
    </div>

    <div class="alert-call-request__buttons">
        <AcceptRequestButton :alert-call-id="associate.info.alertCallId" />
        <RejectRequestButton />
    </div>
</template>

<script setup>
import AcceptRequestButton from './AcceptRequestButton.vue'
import RejectRequestButton from './RejectRequestButton.vue'
import { useCallAlertStore } from '../../../stores/CallAlertStore'

const { associate } = useCallAlertStore()
const filesPath = process.env.VUE_APP_FILES_PATH
</script>