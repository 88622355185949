<template>
    <a href="#" class="alert-call-request__button" @click.prevent="declineCallRequestService()">
        <img src="@/assets/icons/vuesax/linear/close-circle-white.svg" alt="">
        Reject
    </a>
</template>

<script setup>
import { useCallAlertStore } from '../../../stores/CallAlertStore'

const { declineCallRequestService } = useCallAlertStore()
</script>