import { useClientStore } from '@/stores/ClientStore.js'

export function rapidSosEvent() {
    const { client } = useClientStore()

    // * Subscribe to RapidSOS event
    function joinRapidSosEvent() {
        console.log('Join in RapidSOS channel ✅')

        return Echo.private(`rapid-sos.${client.hotelId}`)
    }

    // * Leave to RapidSOS event
    function leaveRapidSosEvent() {
        return Echo.leave(`rapid-sos.${client.hotelId}`)
    }

    return { joinRapidSosEvent, leaveRapidSosEvent }
}