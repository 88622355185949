<template>
    <div class="alert-notch" v-if="showNotch">
        <p>One Or More Associates Need Help!</p>
        
        <RouterLink class="button button--secondary button--small-padding button--text-medium" to="/associates_alert">
            Check Alerts
        </RouterLink>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'

const { alert } = usePanicAlertStore()
const showNotch = ref(false)

watch(alert, () => {
    showNotch.value = alert.panicAlertStatus
})
</script>