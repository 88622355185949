import axios from "axios"
import { reactive } from "vue"
import { useClientStore } from "../stores/ClientStore"
import { useHotelStatusStore } from "../stores/HotelStatusStore"

export function httpRequests() {
    const httpErrors = reactive({ hasErrors: false, message: '', fields: {} })
    const clientStore = useClientStore()
    const hotelStatusStore = useHotelStatusStore()

    // * Setup axios
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios.defaults.baseURL = process.env.VUE_APP_BASE_PATH;

    // * Intercept axios request response after send
    axios.interceptors.response.use(function (response) {
        if (response.status === 200) resetErrors()

        return response;
    });

    async function getRequest(url, headers) {
        try {
            return await axios.get(`/api${url}`, headers);
        } catch (error) {
            return setErrors(error);
        }
    }

    async function postRequest(url, params, headers) {
        try {
            return await axios.post(`/api${url}`, params, headers);
        } catch (error) {
            return setErrors(error);
        }
    }

    async function postFormRequest(url, params) {
        try {
            return await axios.postForm(`/api${url}`, params);
        } catch (error) {
            return setErrors(error);
        }
    }

    async function putRequest(url, data) {
        try {
            return await axios.put(`/api${url}`, data);
        } catch (error) {
            return setErrors(error);
        }
    }

    async function deleteRequest(url) {
        try {
            return await axios.delete(`/api${url}`);
        } catch (error) {
            return setErrors(error);
        }
    }

    function setErrors(error) {
        httpErrors.hasErrors = true;
        httpErrors.message = error.response.data.message;
        httpErrors.fields = error.response.data.fields ?? {};

        if (error.response.status === 401 && error.response.data.message === "Unauthenticated.") {
            // * Reset customer information in local storage
            clientStore.loginStatus()

            // * Reset hotel status information in local storage
            hotelStatusStore.resetHotelStatus()
        }

        if (error.response.status === 500) httpErrors.message = error.response.statusText

        if (error.response.status === 413) httpErrors.message = 'Internal Server Error.'

        return httpErrors;
    }

    function resetErrors() {
        httpErrors.hasErrors = false;
        httpErrors.message = '';
        httpErrors.fields = {};
    }

    return { getRequest, postRequest, postFormRequest, putRequest, deleteRequest, httpErrors, resetErrors }
}