import { useClientStore } from '@/stores/ClientStore.js'

export function panicAlertEvent() {
    const { client } = useClientStore()

    // * Subscribe to panic alert event
    function joinPanicAlerEvent() {
        console.log('Join in panic alert channel ✅')

        return Echo.private(`panic-alert.${client.hotelId}`)
    }

    // * Leave to panic alert event
    function leavePanicALertEvent() {
        Echo.leave(`panic-alert.${client.hotelId}`)
    }

    return { joinPanicAlerEvent, leavePanicALertEvent }
}