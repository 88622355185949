<template>
    <a href="#" class="alert-call-request__button" @click.prevent="acceptCallRequestService">
        <img src="@/assets/icons/vuesax/linear/tick-circle-white.svg" alt="">
        Accept
    </a>
</template>

<script setup>
import { useCallAlertStore } from '../../../stores/CallAlertStore'

const { acceptCallRequestService } = useCallAlertStore()
</script>