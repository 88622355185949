<template>
    <div class="alert-call-request" v-if="notchStatus.show">
        <AssociateInfo />
    </div>
</template>

<script setup>
import AssociateInfo from '../Components/AssociateInfo.vue'
import { useCallAlertStore } from '../../../stores/CallAlertStore'
import { rapidSosEvent } from '../../../sockets-events/RapidSosEvent'
import { onMounted } from 'vue'

const { notchStatus, setCallRequest } = useCallAlertStore()
const { joinRapidSosEvent } = rapidSosEvent()

onMounted(() => {
    joinRapidSosEvent()
        .listen(".rapid-events", (event) => {
            if(event.eventType.eventId == 1) setCallRequest()
            if(event.eventType.eventId == 3) setCallRequest()
        })
})
</script>