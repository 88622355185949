import { useSubscriptionsStore } from '@/stores/SubscriptionStore.js'
import { useClientStore } from "../stores/ClientStore.js"
import { useRouter } from 'vue-router'

export function stripeSubscriptionEvent() {
    const { prosafeLicence } = useSubscriptionsStore()
    const { client } = useClientStore()
    const router = useRouter()

    // * Subscribe to customer subscription event
    function joinStripeSubscriptionEvent() {
        console.log('Join in customer subscription channel ✅')
        
        Echo.private(`customer-subscription.${client.id}`)
            .listen(".subscription-events", (subscriptionStatus) => {
                // return console.log(subscriptionStatus)
                prosafeLicence.type = subscriptionStatus.type
                prosafeLicence.subscriptionId = subscriptionStatus.subscriptionId
                prosafeLicence.redirection = subscriptionStatus.redirection

                router.push(subscriptionStatus.redirection)
            })
    }

    // * Leave to customer subscription event
    function leaveStripeSubscriptionEvent() {
        Echo.leave(`customer-subscription.${client.id}`)
    }

    return { joinStripeSubscriptionEvent, leaveStripeSubscriptionEvent }
}