import { defineStore } from 'pinia'
import { ref, onMounted, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { httpRequests } from '../helpers/HttpRequests.js'

export const useNotificationPermissionsStore = defineStore('permissions', () => {
    const { postRequest } = httpRequests()
    const showModalPermissions = ref(false)
    const permissions = ref(useLocalStorage('permissions', {
        showNotifications: true,
        notificationPermission: false,
        soundPermission: false,
        soundVolume: 1,
        browser: ''
    }))

    onMounted(async () => {
        permissions.value.browser = browserName()

        const response = await postRequest('/web/notifications_permissions/set', permissions.value)

        if (response.hasErrors) return console.error(`Error updating notifications permissions: ${response.message}`)
    })

    function modalPermissions() {
        showModalPermissions.value = !showModalPermissions.value
    }

    function browserName() {
        const userAgent = navigator.userAgent
    
        if (userAgent.indexOf("Edg") > -1) { 
            return "Microsoft Edge"
        } else if (userAgent.indexOf("Chrome") > -1) { 
            return "Chrome"
        } else if (userAgent.indexOf("Firefox") > -1) { 
            return "Firefox"
        } else if (userAgent.indexOf("Safari") > -1) { 
            return "Safari"
        } else if (userAgent.indexOf("Opera") > -1) { 
            return "Opera"
        } else if (userAgent.indexOf("Trident") > -1 || userAgent.indexOf("MSIE") > -1) { 
            return "Internet Explorer"
        }
    }


    return { modalPermissions, showModalPermissions, permissions }
})