import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { httpRequests } from '../helpers/HttpRequests'
import { useRouter } from 'vue-router'
import { useSubscriptionsStore } from './SubscriptionStore.js'
import { useHotelStatusStore } from './HotelStatusStore.js'

export const useClientStore = defineStore('client', () => {
    const { getRequest } = httpRequests()
    const subscriptionStore = useSubscriptionsStore()
    const hotelStatusStore = useHotelStatusStore()
    const router = useRouter()
    const client = ref(useLocalStorage('client', {
        id: '',
        name: '',
        image: '',
        hotelId: '',
        hotel: '',
        hotelAddress: '',
        rolId: '',
        isAuth: false
    }))

    watch(client, () => {
        if (!client.isAuth) return router.push('/login')
    })

    function resetClient() {
        client.value.id = ''
        client.value.name = ''
        client.value.image = ''
        client.value.hotelId = ''
        client.value.hotel = ''
        client.value.rolId = ''
        client.value.isAuth = false
    }

    async function loginStatus() {
        if (client.value.isAuth) {
            const response = await getRequest('/login_status')
            const isLogged = response.data

            if (response.hasErrors) return console.error(`Error getting customer login status: ${response.message}`)

            if (!isLogged) {
                // * Reset customer data
                resetClient()

                // * Reset customer information in local storage
                subscriptionStore.resetProsafeSubscription()

                // * Reset hotel status information in local storage
                hotelStatusStore.resetHotelStatus()

                // * Redirect to login
                return router.push('/login')
            }
        }
    }

    return { client, resetClient, loginStatus }
})