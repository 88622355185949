import { defineStore } from 'pinia'
import { httpRequests } from '../helpers/HttpRequests'
import { reactive } from 'vue'
import { useLocalStorage } from '@vueuse/core'

export const useHotelStatusStore = defineStore('hotelStatus', () => {
    const { getRequest } = httpRequests()
    const hotel = reactive(useLocalStorage('hotelSetup', {
        hotelInformation: false,
        hotelFloors: false,
        hotelRooms: false,
        hotelAreas: false,
        isCompleted: false
    }))

    async function getHotelStatus() {
        const response = await getRequest('/web/hotel/status')

        hotel.value.hotelInformation = response.data.hotelInformation
        hotel.value.hotelFloors = response.data.hotelFloors
        hotel.value.hotelRooms = response.data.hotelRooms
        hotel.value.hotelAreas = response.data.hotelAreas
        hotel.value.isCompleted = response.data.completeSteps
    }

    function resetHotelStatus() {
        hotel.value.hotelInformation = false
        hotel.value.hotelFloors = false
        hotel.value.hotelRooms = false
        hotel.value.hotelAreas = false
        hotel.value.isCompleted = false
    }

    return { hotel, getHotelStatus, resetHotelStatus }
})