import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { httpRequests } from "@/helpers/HttpRequests"
import { useRouter } from 'vue-router'
import { useClientStore } from './ClientStore.js'

export const useSubscriptionsStore = defineStore('subscriptions', () => {
    const { getRequest } = httpRequests()
    const { client } = useClientStore()
    const router = useRouter()
    const prosafeLicence = ref(useLocalStorage('subscription', {
        type: '',
        subscriptionId: '',
        redirection: '',
    }))

    function resetProsafeSubscription() {
        prosafeLicence.value.type = ''
        prosafeLicence.value.subscriptionId = ''
        prosafeLicence.value.redirection = ''
    }

    async function checkProsafeLicenceStatus() {
        if (client.id) {
            const response = await getRequest(`/web/stripe/prosafe_licence_status`)

            if (response.hasErrors) return console.error(`Store error, error getting ProSafe licence status: ${response.message}`)
    
            if (prosafeLicence.value.subscriptionId !== response.data.subscriptionId) { 
                // * Set ProSafe subscription status
                prosafeLicence.value.type = response.data.type
                prosafeLicence.value.subscriptionId = response.data.subscriptionId
        
                // * Redirect to response direction url
                router.push(response.data.redirection)
    
                // * Replace redirection value
                return prosafeLicence.value.redirection = response.data.redirection
            }
        }
    }

    return { prosafeLicence, checkProsafeLicenceStatus, resetProsafeSubscription }
})